import React, { memo } from 'react';
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState';
import { useOptionContractCodeState } from '~/modules/options/shareContainers/useOptionContractCodeState';
import { useOptionMQParamState } from '~/modules/options/shareContainers/useOptionMQParamState';
import { useOpbsPlQueryState } from '~/modules/options/shareContainers/useOpbsPlQueryState';
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState';
import { useOpbsCombineState } from '~/pages/futuresai/opbs/containers/useOpbsCombineState';
import { useTryCalcPositionState } from '~/pages/futuresai/opbs/containers/useTryCalcPositionState';
const OpbsProvider = memo(function OpbsProvider(props) {
    return (<useOptionDaysState.Provider>
      <useOptionContractCodeState.Provider>
        <useOptionMQParamState.Provider>
          <useOpbsPlQueryState.Provider>
            {/* 以上 useOptionDaysState, useOptionContractCodeState, useOptionMQParamState 為參數層 */}
            <useOptionsSourceState.Provider>
              <useOpbsCombineState.Provider>
                {/* 以上 useOptionsSourceState, useOpbsCombineState 為資料層 */}
                <useTryCalcPositionState.Provider>
                  {props.children}
                </useTryCalcPositionState.Provider>
              </useOpbsCombineState.Provider>
            </useOptionsSourceState.Provider>
          </useOpbsPlQueryState.Provider>
        </useOptionMQParamState.Provider>
      </useOptionContractCodeState.Provider>
    </useOptionDaysState.Provider>);
});
export default OpbsProvider;
