import { useMemo } from 'react';
import difference from 'lodash/difference';
import head from 'lodash/head';
import last from 'lodash/last';
import range from 'lodash/range';
import uniq from 'lodash/uniq';
import zipWith from 'lodash/zipWith';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import dayjs from 'dayjs';
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState';
import { useOptionContractCodeState } from '~/modules/options/shareContainers/useOptionContractCodeState';
import { useOptionMQParamState } from '~/modules/options/shareContainers/useOptionMQParamState';
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState';
/* modules/options/utils */
import getSymbolPrice from '~/modules/options/utils/getSymbolPrice';
import getTimeValue from '~/modules/options/utils/getTimeValue';
import { getOptionSymbol } from '~/modules/options/utils/getOptionSymbolUtil';
import { useParseOptionsContractMonthString } from '~/modules/options/utils/useParseOptionsContractMonthString';
import getAtThePriceAvgTimeValue from '~/modules/options/utils/getAtThePriceAvgTimeValue';
import { useOpbsResource } from '~/modules/options/api/useOpbsResource';
import { useSyntheticIndexStore } from '~/modules/options/shareContainers/useSyntheticIndexStore';
const PRICE_OUTLIER = 1000;
const CALL_PUT_DIFF_OUTLIER = 10000;
/**
 * 將兩個來源的選擇權資料合併：
 *
 * - UseOptionsSource 來的資料(稱為 quote)，以及
 * - 此處取得的統計: https://api.futures-op.com/api/v2/opbs
 */
export const useOpbsCombine = () => {
    const { state: mqState } = useOptionMQParamState.useContainer();
    const { state: contractState } = useOptionContractCodeState.useContainer();
    /** 1 - GET 所有選擇權 `報價源` 或是 `某區間報價` */
    const { state: optionSourceState } = useOptionsSourceState.useContainer();
    const { callSource, putSource } = optionSourceState;
    const dayState = useOptionDaysState.useContainer();
    /** 2 - GET 選擇權 `統計表`，圖表主要是拿這個來使用，然後會 */
    const opbs = useOpbsResource({
        from: dayState.state.fromDay,
        to: dayjs.unix((optionSourceState.isBackTest || dayState.state.slidingWindowMode
            ? dayState.state.softToDay?.unix()
            : dayState.state.toDay?.unix()) ?? 0),
        contractMonth: contractState.month,
        q_gte: mqState.paramQPair[0],
        q_lte: mqState.paramQPair[1],
        m_gte: mqState.parameterM && mqState.parameterM > 0 ? mqState.parameterM : undefined,
        m_lte: mqState.parameterM && mqState.parameterM < 0 ? -mqState.parameterM : undefined,
    });
    /** 目前合約 */
    const contract_ = useParseOptionsContractMonthString(contractState.month);
    const currentYear_ = contractState.month?.substring(0, 4) ?? dayjs().year().toString();
    let opbData = opbs.res.data;
    if (opbData)
        opbData = adjustedOpbData(opbData);
    const call = opbData?.call;
    const put = opbData?.put;
    /** 3 - 合併 「報價」 與 「統計表」 */
    let callData = useMemo(() => {
        return zipWith(call?.avg_price || [], call?.lot || [], call?.name || [], call?.price || [], (avgPrice, lot, name, price) => combineQuoteNTable(contract_, currentYear_, callSource, 'C', avgPrice, lot, name, price)).reverse();
    }, [call?.avg_price, call?.lot, call?.name, call?.price, callSource, contract_, currentYear_]);
    /** 4 - 合併 「報價」 與 「統計表」 */
    let putData = useMemo(() => {
        return zipWith(put?.avg_price || [], put?.lot || [], put?.name || [], put?.price || [], (avgPrice, lot, name, price) => combineQuoteNTable(contract_, currentYear_, putSource, 'P', avgPrice, lot, name, price)).reverse();
    }, [put?.avg_price, put?.lot, put?.name, put?.price, putSource, contract_, currentYear_]);
    /** 取得價平和 */
    const callPutStrength = useMemo(() => {
        return zipWith(callData.map(x => x.close), putData.map(x => x.close), (cPrice, pPrice) => (cPrice === 0 || pPrice === 0 ? NaN : cPrice + pPrice));
    }, [callData, putData]);
    /** 取得價平差 */
    const callPutDiff = useMemo(() => {
        return zipWith(callData.map(x => x.close), putData.map(x => x.close), (cPrice, pPrice) => cPrice === 0 || pPrice === 0 ? CALL_PUT_DIFF_OUTLIER : Math.abs(cPrice - pPrice)).filter(diff => diff);
    }, [callData, putData]);
    /** 取得價平點 */
    const minDiff = Math.min(...callPutDiff);
    const atTheMoneyIndex = minDiff === CALL_PUT_DIFF_OUTLIER ? -1 : callPutDiff.indexOf(Math.min(...callPutDiff));
    const atTheMoneyPrice = atTheMoneyIndex === -1 ? 0 : parseInt(callData[atTheMoneyIndex]?.name);
    const syntheticPrice = atTheMoneyIndex === -1
        ? 0
        : atTheMoneyPrice + callData[atTheMoneyIndex]?.close - putData[atTheMoneyIndex]?.close;
    /** 計算時間價值 */
    callData = callData.map(c => getTimeValue(c, syntheticPrice, true));
    putData = putData.map(p => getTimeValue(p, syntheticPrice, false));
    /** 價平時間價值 */
    const atThePriceAvgTimeValue = getAtThePriceAvgTimeValue(callData, putData, syntheticPrice, atTheMoneyIndex);
    useSyntheticIndexStore.syntheticIndexPrice = syntheticPrice;
    useSyntheticIndexStore.atThePriceAvgTimeValue = atThePriceAvgTimeValue;
    const strikePrices_ = useMemo(() => {
        return uniq([...callData, ...putData]
            .map(datum => getSymbolPrice(datum.symbol))
            .filter(datum => datum)
            .sort((a, b) => a - b));
    }, [callData, putData]);
    const extendStrikePrices = useMemo(() => {
        const extendCount = 6;
        const step = Math.min(...strikePrices_
            .map((price, index) => index === strikePrices_.length - 1 ? PRICE_OUTLIER : strikePrices_[index + 1] - price)
            .filter(price => price > 0));
        const rangeStart = head(strikePrices_);
        const rangeEnd = last(strikePrices_);
        if (rangeStart && rangeEnd) {
            return range(rangeStart - extendCount * step, rangeEnd + extendCount * step, step);
        }
        return [];
    }, [strikePrices_]);
    return {
        loading: !opbData || !callData || !putData,
        error: opbs.res.error || null,
        state: {
            callData,
            putData,
            callPutStrength,
            atTheMoneyIndex,
            atTheMoneyPrice,
        },
        props: {
            extendStrikePrices,
        },
        acts: {
            findAll() {
                return [...callData, ...putData];
            },
        },
    };
};
/**
 * 過去曾經有履約價call, put長度一樣但履約價不對稱的問題，因此需要檢查
 *
 * @param data: {call: {...}, put: {...}}
 * @returns
 */
const adjustedOpbData = (data) => {
    const callNames = data?.call?.name;
    const putNames = data?.put?.name;
    if (callNames?.length === 0 || putNames?.length === 0)
        return data;
    /** 完整履約價列表：取得不重複之買賣雙邊履約價 ===> [ 16600, 16700, 16800, 16900, … ] */
    const priceNameKeys = callNames
        ?.concat(putNames ?? [])
        .map(name => parseInt(name))
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort();
    /** 檢查是否與上方得不重複履約價列表有不同 */
    const missingCallKeys = difference(priceNameKeys, callNames?.map(n => parseInt(n)) ?? []);
    const missingPutKeys = difference(priceNameKeys, putNames?.map(n => parseInt(n)) ?? []);
    if (!missingCallKeys && !missingPutKeys)
        return data;
    /** Call目前缺 [17000]，在 `完整履約價列表` 17000 在的位置，補足預設資料 */
    missingCallKeys.forEach(callKey => {
        const indexOfMissingk = priceNameKeys?.indexOf(callKey) ?? -1;
        if (indexOfMissingk === -1)
            return;
        data?.call?.name.splice(indexOfMissingk, 0, `${callKey}C`);
        data?.call?.avg_price.splice(indexOfMissingk, 0, 0);
        data?.call?.price.splice(indexOfMissingk, 0, 0);
        data?.call?.lot.splice(indexOfMissingk, 0, 0);
    });
    /** 同理 */
    missingPutKeys.forEach(putKey => {
        const indexOfMissingk = priceNameKeys?.indexOf(putKey) ?? -1;
        if (indexOfMissingk === -1)
            return;
        data?.put?.name.splice(indexOfMissingk, 0, `${putKey}P`);
        data?.put?.avg_price.splice(indexOfMissingk, 0, 0);
        data?.put?.price.splice(indexOfMissingk, 0, 0);
        data?.put?.lot.splice(indexOfMissingk, 0, 0);
    });
    return data;
};
const combineQuoteNTable = (contract, currentYear, socketSource, type, avgPrice, lot, name, price) => {
    const isMonth = contract?.contractType === 'month';
    const socketDatum = socketSource.find(datum => getSymbolPrice(datum?.symbol) + type === name);
    const symbolString = getOptionSymbol(isMonth, contract, type, parseInt(name), currentYear);
    const tradeValue = price * 50;
    const volume = socketDatum?.volume ?? 0;
    const close = socketDatum?.close ?? 0;
    const cost = avgPrice * lot * -1;
    const prevRef = socketDatum?.prevRef ?? 0;
    const bid = socketDatum?.bid ?? 0;
    const ask = socketDatum?.ask ?? 0;
    const symbol = socketDatum?.symbol ?? symbolString;
    return {
        symbol,
        avgPrice,
        lot,
        name,
        price,
        socketDatum,
        tradeValue,
        volume,
        close,
        cost,
        prevRef,
        bid,
        ask,
        timeValue: 0,
    };
};
export const useOpbsCombineState = createContainer(useOpbsCombine);
