import React, { memo } from 'react';
import { css } from '@emotion/react';
import useMedia from '~/hooks/useMedia';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import DayFromSelect from '~/modules/options/sharedComponents/ComposedDayRangeSelect/DayFromSelect';
import DayToSelect from '~/modules/options/sharedComponents/ComposedDayRangeSelect/DayToSelect';
import { PreciseToggle } from '~/modules/options/sharedComponents/ComposedDayRangeSelect/PreciseToggle';
import { SlidingWindowToggle } from '~/modules/options/sharedComponents/ComposedDayRangeSelect/SlidingWindowToggle';
import ContractsSelect from '~/modules/options/sharedComponents/ContractsSelect';
import { BackTest, RelativeQuotes } from '~/modules/options/sharedComponents/QuoteRelations';
import AccumulateToggle from '~/pages/futuresai/opbs/components/AccumulateToggle';
import ParameterMQSelect from '~/pages/futuresai/opbs/components/ParameterMQSelect';
const Futuresai_Row2 = memo(function Futuresai_Row2() {
    const { isPhone } = useMedia();
    return (<div css={css `
        padding: 8px;
      `}>
      <div css={css `
          ${flex.wrap.default};
          gap: 8px;
        `}>
        <DayFromSelect />
        <DayToSelect />

        <ContractsSelect />
        <ParameterMQSelect.Q />
        <ParameterMQSelect.DoubleM />
      </div>
      <div css={css `
          margin-top: 8px;
          gap: 8px;
        `}>
        <AccumulateToggle />
        <PreciseToggle />
        <SlidingWindowToggle />
        <BackTest />
      </div>
      <div css={css `
          ${isPhone ? flex.v.default : flex.wrap.default};
          margin-top: 8px;
          gap: 8px;
        `}>
        <RelativeQuotes />
      </div>
    </div>);
});
export default Futuresai_Row2;
