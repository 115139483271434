import Bigjs from 'big.js';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
/**
 * Given a number, and then returns a string as Taiwan Dollar representation in Chinese
 *
 * e.g. input `10000` and so output `'1萬'`
 */
export const representationAsNTD = (value) => {
    if (!isNumber(value) || isNaN(value)) {
        console.warn(`${representationAsNTD.name}: expect value to be number, but get ${value}`);
        return '';
    }
    const _value = Bigjs(value).abs();
    const negativeSymbol = value < 0 ? '-' : '';
    if (_value.gte(Bigjs(1000000))) {
        return negativeSymbol + _value.div(1000000).toFixed(2).toString() + ' 百萬元';
    }
    if (_value.gte(Bigjs(10000))) {
        return negativeSymbol + _value.div(10000).toFixed(2).toString() + ' 萬元';
    }
    return negativeSymbol + _value.toFixed(0).toString() + '元';
};
