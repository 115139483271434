import React, { memo } from 'react';
import { Select, MenuItem } from '@mui/material';
import { DaySelect } from '~/components/DaySelect';
import { DateTimePicker } from '~/components/DateTimePicker';
import { MINUTE_SELECTION, useOptionDaysState, } from '~/modules/options/shareContainers/useOptionDaysState';
/**
 * useOptionDaysState 的互動 UI，選擇想要統計資訊的開始時間
 *
 * opbs, option-compare, option-price共用
 */
const DayFromSelect = memo(function DayFromSelect(props) {
    const filter = useOptionDaysState.useContainer();
    return filter.state.slidingWindowMode ? (<Select variant='outlined' size='small' value={filter.state.fromMinute} onChange={e => {
            if (typeof e.target.value === 'number') {
                const minuteToMinus = e.target.value;
                filter.acts.setWindowLengthMinute(minuteToMinus);
                filter.acts.setFromDay(filter.state.toDay?.add(-minuteToMinus, 'minute'));
            }
        }}>
      {MINUTE_SELECTION.map(v => (<MenuItem key={v} value={v}>
          {v}分鐘內資料
        </MenuItem>))}
    </Select>) : filter.state.preciseMode ? (<DateTimePicker value={filter.state.fromDay?.format('YYYY-MM-DDTHH:mm')} label='開始時間' onChangeDelegate={filter.acts.setFromDay}/>) : (<DaySelect days={filter.props.openDays} value={filter.state.fromDay} label='開始時間' onChange={filter.acts.setFromDay}/>);
});
export default DayFromSelect;
