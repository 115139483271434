import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React, { memo } from 'react';
import { useOptionDaysState } from '~/modules/options/shareContainers/useOptionDaysState';
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState';
/**
 * useOptionDaysState 的互動 UI，選擇是否是累積資訊
 *
 * 用來決定該合約的from是否「從有效時間開始」～「官方結束時間」，
 * 或是當下交易的「官方開始時間」～「官方結束時間」
 *
 * */
const AccumulateToggle = memo(function AccumulateToggle() {
    const day = useOptionDaysState.useContainer();
    const { state } = useOptionsSourceState.useContainer();
    const handleChange = (e) => {
        if (e.target.checked) {
            day.acts.selectAsAccumulate();
            return;
        }
        day.acts.selectAsNow();
    };
    return (<FormControl>
      <FormControlLabel onChange={handleChange} value='start' control={<Checkbox color='primary' defaultChecked={true}/>} label='當周累積' labelPlacement='end'/>
    </FormControl>);
});
export default AccumulateToggle;
