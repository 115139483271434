import { css } from '@emotion/react';
import max from 'lodash/max';
import React, { memo } from 'react';
import { Loading } from '~/components/Loading';
import { useOpbsCombineState } from '~/pages/futuresai/opbs/containers/useOpbsCombineState';
import OpbsHorizontalBarChart from '~/pages/futuresai/opbs/components/OpbsHorizontalBarChart';
import OpbsPriceTableChart from '~/pages/futuresai/opbs/components/OpbsPriceTableChart';
import useMedia from '~/hooks/useMedia';
import { pureGrid } from '~/modules/AppLayout/FlexGridCss';
/** 選擇權分析之即時圖表 由三種圖表組成 買權圖表 + C+P + 賣權圖表 */
const OpbsComposedChart = memo(function OpbsComposedChart() {
    const txOptions = useOpbsCombineState.useContainer();
    const callData = txOptions.state.callData;
    const putData = txOptions.state.putData;
    const callPutStrength = txOptions.state.callPutStrength;
    const { isPhone } = useMedia();
    if (!callData.length && !putData.length)
        return txOptions.loading ? <Loading /> : <span>沒有資料，請重新選取日期範圍</span>;
    const maxLot = max([...callData, ...putData].map(item => Math.abs(item.lot))) ?? 2000;
    const maxRev = max([...callData, ...putData].map(item => Math.abs((item.close - item.avgPrice) * item.lot * 50))) ?? 4000000;
    const { atTheMoneyIndex, atTheMoneyPrice } = txOptions.state;
    const isDataLessThanTenItems = callData.length < 10;
    const opbsInnerContainerCss = css `
    flex-grow: 1;
    ${pureGrid};
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
    margin: 20px 0 0 0;
  `;
    const cpSumCss = css `
    width: ${isPhone ? '40px' : '44px'};
    height: ${callData.length * (isDataLessThanTenItems ? 64 : 40)}px;
  `;
    // z-index是為了讓她們都在cpSum之上，否則hover tooltip會被擋住
    // WORKAROUND: chrome版本會有高度消失的問題
    const chartContainerCss = css `
    flex-grow: 1;
    min-width: 0;
    z-index: 4;
    height: ${callData.length * (isDataLessThanTenItems ? 64 : 40)}px;
  `;
    return (<div css={opbsInnerContainerCss}>
      <div css={chartContainerCss}>
        <OpbsHorizontalBarChart atTheMoneyPrice={atTheMoneyPrice} title='買權（CALL）' data={callData} maxTick={maxLot} maxRev={maxRev}/>
      </div>
      <div css={cpSumCss}>
        <OpbsPriceTableChart title={'C+P'} data={callPutStrength} minDiffIndex={atTheMoneyIndex}/>
      </div>
      <div css={chartContainerCss}>
        <OpbsHorizontalBarChart title='賣權（PUT）' atTheMoneyPrice={atTheMoneyPrice} data={putData} mirrorY={true} maxTick={maxLot} maxRev={maxRev}/>
      </div>
    </div>);
});
export default OpbsComposedChart;
